import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout";
import { styles } from "../../shared-styles";
import { article } from "./index.module.css";

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog">
      <section style={styles.ContentSection}>
        {
          data.allMdx.nodes.map(node => (
            <article className={article} key={node.id}>
              {/*<h2>*/}
                <Link to={`/blog/${node.slug}`}>
                  {node.frontmatter.title}
                </Link>
              {/*</h2>*/}
              <p style={{fontSize: '16px', marginTop: "10px"}}>Publicat <strong>{node.frontmatter.date}</strong></p>
            </article>
          ))
        }
      </section>
    </Layout>
  );
};

export const query = graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
            nodes {
                frontmatter {
                    date(formatString: "D MMMM, YYYY", locale: "ro-RO")
                    title
                }
                id
                slug
            }
        }
    }
`;

export default BlogPage;
